import "./Sidebar.css";
import SidebarButton from "./SidebarButton";
import { ReactComponent as AdfIcon } from "./dental-tooth-icon.svg";
import { ReactComponent as AssetsIcon } from "./assets-icon.svg";
import { Dispatch, SetStateAction } from "react";

interface SidebarProps {
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
}

const Sidebar = ({ currentTab, setCurrentTab }: SidebarProps) => {
  return (
    <div className="Sidebar-container">
      <SidebarButton
        relatedTab="adf-tab"
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        text="Adfs"
        icon={AdfIcon}
      />
      <SidebarButton
        relatedTab="asset-tab"
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        text="Assets"
        icon={AssetsIcon}
      />
    </div>
  );
};

export default Sidebar;
