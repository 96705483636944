import { Dispatch, SetStateAction } from "react";
import UploadDialog from "./UploadDialog";
import { ArtifactNameValidationResult } from "../DataStructure/artifact-name-validation";

interface UploadAssetDialogProps {
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}

const UploadAssetDialog = (props: UploadAssetDialogProps) => {
  return (
    <UploadDialog
      title="Upload new asset"
      setShowPopup={props.setShowPopup}
      namePlaceholder="name.some"
      artifactType="asset"
      nameValidator={(value: string) => {
        let status: ArtifactNameValidationResult = {
          failed: false,
          error: undefined,
        };
        if (value.length < 5) {
          status = { failed: true, error: "Too short name" };
        }
        return status;
      }}
    ></UploadDialog>
  );
};

export default UploadAssetDialog;
