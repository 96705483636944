import {
  DynamoDBDocumentClient,
  GetCommand,
  GetCommandOutput,
} from "@aws-sdk/lib-dynamodb";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Auth } from "aws-amplify";
import { applicationConfig } from "./config";

let dynamoDbClient: DynamoDBDocumentClient | undefined;
let expirationTime: number;

const getDynamoDbClient = async (): Promise<DynamoDBDocumentClient> => {
  if (
    dynamoDbClient !== undefined &&
    new Date().getTime() < expirationTime * 1000
  ) {
    return dynamoDbClient;
  } else {
    expirationTime = (await Auth.currentSession())
      .getIdToken()
      .decodePayload().exp;
    dynamoDbClient = DynamoDBDocumentClient.from(
      new DynamoDBClient({
        region: applicationConfig.dynamoDb.region,
        credentials: await fromCognitoIdentityPool({
          clientConfig: { region: applicationConfig.cognito.region },
          identityPoolId: applicationConfig.cognito.identityPoolId,
          logins: {
            [applicationConfig.cognito.identityPoolProvider]: (
              await Auth.currentSession()
            )
              .getIdToken()
              .getJwtToken(),
          },
        }),
      })
    );
    return dynamoDbClient;
  }
};

export const getWccStatuses = async (
  key: string
): Promise<{ [key: string]: string } | undefined> => {
  const client = await getDynamoDbClient();

  return (
    (await client.send(
      // @ts-ignore
      new GetCommand({
        TableName: applicationConfig.dynamoDb.statusesTableName,
        ConsistentRead: true,
        Key: { artifactId: key },
      })
    )) as GetCommandOutput
  ).Item!.status;
};
