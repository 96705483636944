import {
  CopyObjectCommand,
  DeleteObjectCommand,
  GetObjectTaggingCommand,
  HeadObjectCommand,
  ListObjectsV2Command,
  PutObjectCommand,
  S3Client,
  S3ServiceException,
  Tag,
  TaggingDirective,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Auth } from "aws-amplify";
import { Dispatch, SetStateAction } from "react";
import { applicationConfig } from "./config";

export type s3Data = Array<any>;

export interface DataOperationResult {
  isFailed: boolean;
  error?: string;
}

let s3Client: S3Client | undefined;
let expirationTime: number;

const getS3Client = async (): Promise<S3Client> => {
  if (s3Client !== undefined && new Date().getTime() < expirationTime * 1000) {
    return s3Client;
  } else {
    expirationTime = (await Auth.currentSession())
      .getIdToken()
      .decodePayload().exp;
    s3Client = new S3Client({
      region: applicationConfig.s3.region,
      credentials: await fromCognitoIdentityPool({
        clientConfig: { region: applicationConfig.cognito.region },
        identityPoolId: applicationConfig.cognito.identityPoolId,
        logins: {
          [applicationConfig.cognito.identityPoolProvider]: (
            await Auth.currentSession()
          )
            .getIdToken()
            .getJwtToken(),
        },
      }),
    });
    return s3Client;
  }
};

export const dataFetch = async (
  prefix: string,
  data: s3Data | undefined,
  setData: Dispatch<SetStateAction<s3Data | undefined>>,
  nextToken: string | undefined,
  setNextToken: Dispatch<SetStateAction<string | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  const client = await getS3Client();

  const response = await client.send(
    new ListObjectsV2Command({
      Bucket: applicationConfig.s3.artifactsBucketName,
      Prefix: prefix,
      MaxKeys: 10,
      ContinuationToken: nextToken,
    })
  );
  setData([...(data ?? []), ...((response.Contents ?? []) as s3Data)]);
  setNextToken(response.NextContinuationToken);
  setIsLoading(false);
};

export const moveObject = async (
  key: string,
  targetKey: string,
  tags: string
) => {
  const client = await getS3Client();
  await client.send(
    new CopyObjectCommand({
      Bucket: applicationConfig.s3.artifactsBucketName,
      CopySource: `${applicationConfig.s3.artifactsBucketName}/${key}`,
      Key: targetKey,
      Tagging: tags,
      TaggingDirective: TaggingDirective.REPLACE,
    })
  );
  await client.send(
    new DeleteObjectCommand({
      Bucket: applicationConfig.s3.artifactsBucketName,
      Key: key,
    })
  );
};

export const deleteObject = async (key: string) => {
  const client = await getS3Client();
  await client.send(
    new DeleteObjectCommand({
      Bucket: applicationConfig.s3.artifactsBucketName,
      Key: key,
    })
  );
};

export const getObjectTags = async (
  key: string
): Promise<Tag[] | undefined> => {
  const client = await getS3Client();
  const response = await client.send(
    new GetObjectTaggingCommand({
      Bucket: applicationConfig.s3.artifactsBucketName,
      Key: key,
    })
  );

  return response.TagSet;
};

export const dataUpload = async (
  prefix: string,
  artifactName: string,
  file: File,
  setIsUploading: Dispatch<SetStateAction<boolean>>,
  setResult: Dispatch<SetStateAction<DataOperationResult | undefined>>
) => {
  setIsUploading(true);

  const tokenPayload = (await Auth.currentSession())
    .getIdToken()
    .decodePayload();

  const client = await getS3Client();

  const artifactPath = `${prefix}/${artifactName}`;

  const headCommand = new HeadObjectCommand({
    Bucket: applicationConfig.s3.artifactsBucketName,
    Key: artifactPath,
  });

  let result: DataOperationResult;

  try {
    await client.send(headCommand);

    result = {
      isFailed: true,
      error: "Artifact with same name already exists",
    };
  } catch (error) {
    if (
      error instanceof S3ServiceException &&
      error.$metadata.httpStatusCode === 404
    ) {
      await client.send(
        new PutObjectCommand({
          Bucket: applicationConfig.s3.artifactsBucketName,
          Key: artifactPath,
          Body: file,
          Tagging: `Owner=${encodeURIComponent(
            `${tokenPayload.given_name + " " + tokenPayload.family_name}`
          )}`,
        })
      );
      result = { isFailed: false };
    } else {
      result = {
        isFailed: true,
        error: "Something happened",
      };
    }
  }

  setIsUploading(false);
  setResult(result);
};
