import "./ContrastButton.css";
import GeneralButton from "./GeneralButton";

interface ContrastButtonProps {
  text: string;
  enabled: boolean;
  onClick: () => void;
}

const ContrastButton = (props: ContrastButtonProps) => {
  return (
    <GeneralButton
      text={props.text}
      enabled={props.enabled}
      onClick={props.onClick}
      classNamesContainer={[
        `ContrastButton-container${props.enabled ? "" : "-disabled"}`,
      ]}
      classNamesText={["ContrastButton-text"]}
    ></GeneralButton>
  );
};

export default ContrastButton;
