import { Dispatch, SetStateAction, useEffect, useState } from "react";
import WccStatusesBlock from "../WccStatusesBlock";
import { getWccStatuses } from "../dynamodb-data-operations";
import ItemDescriptionPopup from "./ItemDescriptionPopup";
import { s3Data } from "../s3-data-operations";

interface ItemDescriptionPopupAdfProps {
  title: string;
  artifactKey: string;
  creatingDate: Date;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  index: number;
  data: s3Data | undefined;
  setData: Dispatch<SetStateAction<s3Data | undefined>>;
}

const ItemDescriptionPopupAdf = (props: ItemDescriptionPopupAdfProps) => {
  const [preparedWcc, setPreapredWcc] = useState<
    { [key: string]: string } | undefined
  >();

  useEffect(() => {
    getWccStatuses(props.title).then((wccList) => setPreapredWcc(wccList));
  }, [props.title]);

  return (
    <ItemDescriptionPopup
      title={props.title}
      onClose={() => {
        props.setShowPopup(false);
        if (props.onClose !== undefined) {
          props.onClose();
        }
      }}
      artifactKey={props.artifactKey}
      creatingDate={props.creatingDate}
      setShowPopup={props.setShowPopup}
      index={props.index}
      data={props.data}
      setData={props.setData}
    >
      <WccStatusesBlock
        artifactName={props.artifactKey}
        values={preparedWcc}
      ></WccStatusesBlock>
    </ItemDescriptionPopup>
  );
};

export default ItemDescriptionPopupAdf;
