import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./ArtifactListToolbar.css";
import ContrastButton from "./Buttons/ContrastButton";
import UploadAdfDialog from "./Popups/UploadAdfDialog";
import UploadAssetDialog from "./Popups/UploadAssetDialog";

interface ArtifactListToolbarProps {
  currentTab: string;
  isLoading: boolean;
  searchPattern: string;
  setSearchPattern: Dispatch<SetStateAction<string>>;
}

const ArtifactListToolbar = (props: ArtifactListToolbarProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const [searchPattern, setSearchPattern] = useState(props.searchPattern);
  const uploadButtonName = `Upload new ${
    props.currentTab === "adf-tab" ? "adf" : "asset"
  }`;

  useEffect(() => {
    setSearchPattern(props.searchPattern);
  }, [props.searchPattern]);

  const uploadDialog =
    props.currentTab === "adf-tab" ? (
      <UploadAdfDialog setShowPopup={setShowPopup} />
    ) : (
      <UploadAssetDialog setShowPopup={setShowPopup} />
    );

  return (
    <div>
      <div className="ArtifactListToolbar-container">
        <div
          className="ArtifactListToolbar-item-1"
          style={{ paddingTop: "18px" }}
        >
          <input
            type="text"
            value={searchPattern}
            style={{ margin: "0" }}
            placeholder="Name..."
            onChange={(event: any) => {
              setSearchPattern(event.target.value);
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                props.setSearchPattern(event.target.value);
              }
            }}
            disabled={props.isLoading}
          />
          <p className="ArtifactListToolbar-search-hint">
            You can search by prefix only
          </p>
        </div>
        <div className="ArtifactListToolbar-item-2">
          <ContrastButton
            text={uploadButtonName}
            enabled={!props.isLoading}
            onClick={() => {
              setShowPopup(true);
            }}
          ></ContrastButton>
        </div>
      </div>
      {showPopup && uploadDialog}
    </div>
  );
};

export default ArtifactListToolbar;
