import LoadingSpinner from "./LoadingSpinner";
import "./WccStatusesBlock.css";

interface WccStatusesBlockProps {
  artifactName: string;
  values: { [key: string]: string } | undefined;
}

const wccList = (artifactName: string, values: { [key: string]: string }) => {
  return (
    <div className="WccStatusesBlock-fixed-container">
      {Object.entries(values).map(([version, status], _) => (
        <div key={artifactName + version}>
          <div className="WccStatusesBlock-values-container">
            <b className="WccStatusesBlock-value">{version}</b>
            <p className="WccStatusesBlock-value">{status}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const WccStatusesBlock = (props: WccStatusesBlockProps) => {
  return (
    <div className="WccStatusesBlock-container">
      <b className="WccStatusesBlock-header">Wcc preparation status</b>
      {props.values !== undefined ? (
        wccList(props.artifactName, props.values)
      ) : (
        <div className="WccStatusesBlock-spinner-container">
          <LoadingSpinner size="big" />
        </div>
      )}
    </div>
  );
};

export default WccStatusesBlock;
