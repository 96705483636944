export const applicationConfig = {
  s3: {
    region: "us-east-2",
    artifactsBucketName: "shaihuludartifactsv4-main-ohio",
  },
  dynamoDb: {
    region: "us-east-2",
    statusesTableName:
      "ShaiHuludArtifactsV4Ohio-StatusTable0F76785B-1M2NW8RERVKZS",
  },
  wccExporterDefaultVersion: "0.2.0.1486",
  webSocketUrl: "wss://3r6c6b7mi7.execute-api.us-east-2.amazonaws.com/default",
  cognito: {
    region: "us-east-2",
    userPoolId: "us-east-2_FfxT9hQHL",
    userPoolAppClientId: "4ar9b8jdu2v195uea31nhl8sg5",
    oauth: {
      domain: "auth-shai-hulud.auth.us-east-2.amazoncognito.com",
    },
    identityPoolId: "us-east-2:31fe7e9d-33c4-49c5-b612-60fb3cd18569",
    identityPoolProvider:
      "cognito-idp.us-east-2.amazonaws.com/us-east-2_FfxT9hQHL",
  },
};
