import { Dispatch, SetStateAction, useState } from "react";
import ContrastButton from "../Buttons/ContrastButton";
import LoadingSpinner from "../LoadingSpinner";
import "./UploadDialog.css";
import TextInput from "../TextInput";
import Popup from "./Popup";
import { ArtifactNameValidator } from "../DataStructure/artifact-name-validation";
import UploadResultBlock from "../UploadResultBlock";
import { DataOperationResult, dataUpload } from "../s3-data-operations";

interface UploadDialogProps {
  title: string;
  namePlaceholder: string;
  artifactType: string;
  nameValidator: ArtifactNameValidator;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  children?: JSX.Element;
  onClose?: () => void;
  artifactName?: string | undefined;
  setArtifactName?: Dispatch<SetStateAction<string | undefined>>;
}

const UploadDialog = (props: UploadDialogProps) => {
  const [artifactName, setArtifactName] = useState<string | undefined>(
    props.artifactName
  );
  const [file, setFile] = useState<File | undefined>();
  const [isUploading, setIsUploading] = useState(false);
  const [isNameCorrect, setIsNameCorrect] = useState<boolean>(false);
  const [result, setResult] = useState<DataOperationResult | undefined>();

  return (
    <Popup
      title={props.title}
      show={true}
      onClose={(_: any) => {
        props.setShowPopup(false);
        if (props.onClose !== undefined) {
          props.onClose();
        }
      }}
    >
      <div>
        <div className="UploadDialog-content">
          <TextInput
            header="Name"
            value={artifactName}
            placeholder={props.namePlaceholder}
            validateInput={props.nameValidator}
            setValue={setArtifactName}
            onError={() => {
              setIsNameCorrect(false);
            }}
            onSuccess={() => {
              setIsNameCorrect(true);
            }}
          ></TextInput>
          <input
            className="UploadDialog-fileinput"
            type="file"
            onChange={({ target: { files } }) => {
              setFile(files![0]);
              if (artifactName === undefined) {
                const result = props.nameValidator(files![0].name);
                if (!result.failed) {
                  setArtifactName(files![0].name);
                  setIsNameCorrect(true);
                }
              }
            }}
          />
        </div>
        <div className="UploadDialog-footer UploadDialog-button-to-spinner">
          {isUploading ? (
            <LoadingSpinner size="big" />
          ) : (
            <ContrastButton
              text="Upload"
              onClick={() => {
                setResult(undefined);
                dataUpload(
                  props.artifactType,
                  artifactName!,
                  file!,
                  setIsUploading,
                  setResult
                );
                if (props.setArtifactName !== undefined) {
                  props.setArtifactName(artifactName);
                }
              }}
              enabled={isNameCorrect && file !== undefined}
            ></ContrastButton>
          )}
        </div>
        <div className="UploadDialog-footer">
          {result !== undefined ? (
            <UploadResultBlock result={result}>
              {props.children}
            </UploadResultBlock>
          ) : (
            false
          )}
        </div>
      </div>
    </Popup>
  );
};

export default UploadDialog;
