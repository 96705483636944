import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./ItemDescriptionPopup.css";
import Popup from "./Popup";
import { getObjectTags, deleteObject, s3Data } from "../s3-data-operations";
import { Tag } from "@aws-sdk/client-s3";
import { Auth } from "aws-amplify";
import TextForm from "../TextForm";
import ContrastButton from "../Buttons/ContrastButton";

interface UserInfo {
  fullName: string;
  role: string;
}

interface ItemDescriptionPopupProps {
  title: string;
  artifactKey: string;
  creatingDate: Date;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  children?: JSX.Element;
  index: number;
  data: s3Data | undefined;
  setData: Dispatch<SetStateAction<s3Data | undefined>>;
}

const ItemDescriptionPopup = (props: ItemDescriptionPopupProps) => {
  const [owner, setOwner] = useState<string | undefined>();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();

  const getOwner = (tags: Tag[] | undefined): string => {
    let owner: string = "Anonimus cat";

    if (tags !== undefined) {
      const owners = tags.filter((tag) => tag.Key === "Owner");
      if (owners.length > 0) {
        owner = owners[0].Value!;
      }
    }
    return owner;
  };

  useEffect(() => {
    getObjectTags(props.artifactKey).then((tags) => setOwner(getOwner(tags)));
  }, [props.artifactKey]);

  useEffect(() => {
    Auth.currentSession().then((info) => {
      const payload = info.getIdToken().decodePayload();
      setUserInfo({
        fullName: payload.given_name + " " + payload.family_name,
        role: payload["custom:role"],
      });
    });
  }, []);

  return (
    <Popup
      title={props.title}
      show={true}
      onClose={(_: any) => {
        props.setShowPopup(false);
        if (props.onClose !== undefined) {
          props.onClose();
        }
      }}
    >
      <div>
        <TextForm header="Name" value={props.title}></TextForm>
        <TextForm
          header="Creating Date"
          value={props.creatingDate.toDateString()}
        ></TextForm>
        <TextForm header="Owner" value={owner}></TextForm>
        {props.children}
        <div className="ItemDescriptionPopup-container-centered">
          <ContrastButton
            text="Remove"
            enabled={
              userInfo !== undefined &&
              (userInfo.fullName === owner || userInfo.role === "Admin")
            }
            onClick={() => {
              deleteObject(props.artifactKey);
              props.setShowPopup(false);
              if (props.onClose !== undefined) {
                props.onClose();
              }
              if (props.data !== undefined) {
                const tempData = props.data.slice();
                tempData.splice(props.index, 1);
                props.setData(tempData);
              }
            }}
          />
        </div>
      </div>
    </Popup>
  );
};

export default ItemDescriptionPopup;
