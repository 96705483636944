import { Dispatch, SetStateAction, useState } from "react";
import "./ArtifactListItem.css";
import ItemDescriptionPopup from "./Popups/ItemDescriptionPopup";
import ItemDescriptionPopupAdf from "./Popups/ItemDescriptionPopupAdf";
import { s3Data } from "./s3-data-operations";

interface ArtifactListProps {
  key: string;
  name: string | undefined;
  lastModified: Date;
  artifactKey: string;
  currentTab: string;
  index: number;
  data: s3Data | undefined;
  setData: Dispatch<SetStateAction<s3Data | undefined>>;
}

const ArtifactListItem = (props: ArtifactListProps) => {
  const [showPopup, setShowPopup] = useState(false);

  const descriptionPopup =
    props.currentTab === "adf-tab" ? (
      <ItemDescriptionPopupAdf
        title={props.name ?? "Unknown cat"}
        artifactKey={props.artifactKey}
        creatingDate={props.lastModified}
        setShowPopup={setShowPopup}
        index={props.index}
        data={props.data}
        setData={props.setData}
      />
    ) : (
      <ItemDescriptionPopup
        title={props.name ?? "Unknown cat"}
        artifactKey={props.artifactKey}
        creatingDate={props.lastModified}
        setShowPopup={setShowPopup}
        index={props.index}
        data={props.data}
        setData={props.setData}
      />
    );

  return (
    <div>
      {showPopup ? descriptionPopup : false}
      <div
        className="ArtifactListItem-container"
        onClick={() => {
          setShowPopup(true);
        }}
      >
        <p className="ArtifactListItem-element">
          {props.name === undefined
            ? "Unknown cat"
            : props.name.length < 48
            ? props.name
            : props.name.slice(0, 46) + "..."}
        </p>
        <p className="ArtifactListItem-element">
          {props.lastModified.toDateString()}
        </p>
      </div>
    </div>
  );
};

export default ArtifactListItem;
