import { useEffect, useState } from "react";
import "./App.css";
import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";
import ArtifactList from "./ArtifactList";

import "@aws-amplify/ui-react/styles.css";

import { Amplify, Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { applicationConfig } from "./config";

function App() {
  const [currentTab, setCurrentTab] = useState("adf-tab");
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: applicationConfig.cognito.region,
        userPoolId: applicationConfig.cognito.userPoolId,
        userPoolWebClientId: applicationConfig.cognito.userPoolAppClientId,
        mandatorySignIn: true,
        oauth: {
          domain: applicationConfig.cognito.oauth.domain,
          scope: ["email", "openid", "profile"],
          redirectSignIn:
            window.location.protocol + "//" + window.location.host,
          redirectSignOut:
            window.location.protocol + "//" + window.location.host,
          responseType: "code",
        },
      },
    });
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event);
      console.log(data);
      switch (event) {
        case "signIn":
          console.log("Start process");
          break;
        case "cognitoHostedUI":
          console.log("Authenticated...");
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => setUser(currentUser))
      .catch(() => Auth.federatedSignIn());
  }, []);

  const appBody = (
    <div className="App-some">
      <Sidebar currentTab={currentTab} setCurrentTab={setCurrentTab}></Sidebar>
      <ArtifactList currentTab={currentTab} />
    </div>
  );

  return (
    <div className="App">
      <Toolbar user={user} />
      {user !== undefined ? appBody : false}
    </div>
  );
}

export default App;
