import LoadingSpinner from "./LoadingSpinner";
import "./TextForm.css";

interface TextFormProps {
  header: string;
  value: string | undefined;
}

const TextForm = (props: TextFormProps) => {
  return (
    <div className="TextForm-container">
      <b className="TextForm-header">{props.header}</b>
      <div className="TextForm-value-container">
        {props.value !== undefined ? (
          <p className="TextForm-value">{props.value}</p>
        ) : (
          <LoadingSpinner size="small" />
        )}
      </div>
    </div>
  );
};

export default TextForm;
