import { MouseEventHandler } from "react";
import "./GeneralButton.css";

interface GeneralButtonProps {
  enabled: boolean;
  text: string;
  classNamesContainer: string[];
  classNamesText: string[];
  onClick: MouseEventHandler<HTMLDivElement>;
}

const GeneralButton = (props: GeneralButtonProps) => {
  return (
    <div
      className={[
        `GeneralButton-container${props.enabled ? "" : "-disabled"}`,
        ...props.classNamesContainer,
      ].join(" ")}
      onClick={props.onClick}
    >
      <p className={["GeneralButton-text", ...props.classNamesText].join(" ")}>
        {props.text}
      </p>
    </div>
  );
};

export default GeneralButton;
