import { Dispatch, FunctionComponent, SVGProps, SetStateAction } from "react";
import "./SidebarButton.css";

interface SidebarButtonProps {
  text: string;
  relatedTab: string;
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const SidebarButton = (props: SidebarButtonProps) => {
  let containerClass: string;
  let textClass: string;
  let iconContainerClass: string;
  if (props.currentTab === props.relatedTab) {
    containerClass = "SidebarButton-container-active";
    textClass = "SidebarButton-text-active";
    iconContainerClass = "SidebarButton-logo-container-active";
  } else {
    containerClass = "SidebarButton-container";
    textClass = "SidebarButton-text";
    iconContainerClass = "SidebarButton-logo-container";
  }

  return (
    <div
      className={containerClass}
      onClick={() => {
        props.setCurrentTab(props.relatedTab);
      }}
    >
      <props.icon className={iconContainerClass} height="40px" />
      <p className={textClass}>{props.text}</p>
    </div>
  );
};

export default SidebarButton;
