import { useEffect, useState } from "react";
import "./ArtifactList.css";
import ArtifactListItem from "./ArtifactListItem";
import ContrastButton from "./Buttons/ContrastButton";
import LoadingSpinner from "./LoadingSpinner";
import ArtifactListToolbar from "./ArtifactListToolbar";
import { dataFetch, s3Data } from "./s3-data-operations";

interface ArtifactListProps {
  currentTab: string;
}

const ArtifactList = (props: ArtifactListProps) => {
  const [data, setData] = useState<s3Data | undefined>();
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [searchPattern, setSearchPattern] = useState<string>("");

  const prefix = props.currentTab === "adf-tab" ? "adf/" : "asset/";

  useEffect(() => {
    setIsLoading(true);
    setSearchPattern("");
    setData(undefined);
    dataFetch(
      prefix,
      undefined,
      setData,
      undefined,
      setNextToken,
      setIsLoading
    );
  }, [prefix]);

  useEffect(() => {
    setIsLoading(true);
    setData(undefined);
    dataFetch(
      `${prefix}${searchPattern}`,
      undefined,
      setData,
      undefined,
      setNextToken,
      setIsLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPattern]);

  const centredButton = (
    <div className="ArtifactList-container-centered">
      <ContrastButton
        text="More"
        enabled={true}
        onClick={() => {
          setIsLoading(true);
          dataFetch(
            `${prefix}${searchPattern ?? ""}`,
            data,
            setData,
            nextToken,
            setNextToken,
            setIsLoading
          );
        }}
      ></ContrastButton>
    </div>
  );

  const loadingSpinner = (
    <div className="ArtifactList-container-centered">
      <LoadingSpinner size="big" />
    </div>
  );

  const artifactList = () => {
    if (data!.length === 0) {
      return <p>{"Nothing with such prefix :("}</p>;
    } else {
      return (
        <div>
          {data!.map((item, index) => (
            <div>
              <ArtifactListItem
                artifactKey={item.Key}
                key={item.Key}
                name={(item.Key as string).split(prefix)[1]}
                lastModified={item.LastModified}
                currentTab={props.currentTab}
                index={index}
                data={data}
                setData={setData}
              />
              <hr className="ArtifactList-item-separator" />
            </div>
          ))}
          {isLoading
            ? loadingSpinner
            : nextToken !== undefined
            ? centredButton
            : false}
        </div>
      );
    }
  };

  return (
    <div className="ArtifactList-tab">
      <ArtifactListToolbar
        searchPattern={searchPattern}
        currentTab={props.currentTab}
        isLoading={isLoading}
        setSearchPattern={setSearchPattern}
      ></ArtifactListToolbar>
      <div className="ArtifactList-list-container">
        <div className="Artifact-list-container-toolbar">
          <b className="Artifact-list-container-toolbar-item">Name</b>
          <b className="Artifact-list-container-toolbar-item">Creating date</b>
        </div>
        {data !== undefined ? artifactList() : loadingSpinner}
      </div>
    </div>
  );
};

export default ArtifactList;
