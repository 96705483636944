import { Dispatch, SetStateAction, useState } from "react";

import { w3cwebsocket } from "websocket";
import LoadingSpinner from "./LoadingSpinner";
import "./CheckWccBlock.css";
import ContrastButton from "./Buttons/ContrastButton";
import { applicationConfig } from "./config";

interface UploadAdfDialogProps {
  setWebsocketClient: Dispatch<SetStateAction<w3cwebsocket | undefined>>;
  artifactName: string;
}

const awaitWccReady = (
  wccExporterTargetVersion: string,
  artifactName: string,
  setWebsocketClient: Dispatch<SetStateAction<w3cwebsocket | undefined>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setResult: Dispatch<SetStateAction<string | undefined>>
): void => {
  var client = new w3cwebsocket(
    `${applicationConfig.webSocketUrl}?artifactId=${artifactName}&targetVersion=${wccExporterTargetVersion}`
  );

  client.onerror = function () {
    console.log("Connection Error");
  };

  client.onopen = function () {
    console.log("WebSocket Client Connected");
  };

  client.onclose = function () {
    console.log("echo-protocol Client Closed");
  };

  client.onmessage = function (e) {
    client.close();
    setIsLoading(false);
    setResult(e.data.toString());
  };

  setWebsocketClient(client);
};

const CheckWccBlock = (props: UploadAdfDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<string | undefined>();

  return (
    <div className="CheckWccBlock-container">
      <p className="CheckWccBlock-info-text">{`All wcc will be ready soon, you can check status for WccExporter v${applicationConfig.wccExporterDefaultVersion}`}</p>
      <div className="UploadDialog-footer UploadDialog-button-to-spinner">
        {isLoading ? (
          <LoadingSpinner size="big" />
        ) : result === undefined ? (
          <ContrastButton
            text="Check status"
            enabled={true}
            onClick={() => {
              setIsLoading(true);
              awaitWccReady(
                applicationConfig.wccExporterDefaultVersion,
                props.artifactName,
                props.setWebsocketClient,
                setIsLoading,
                setResult
              );
            }}
          />
        ) : (
          <p className="CheckWccBlock-result-text">{result}</p>
        )}
      </div>
    </div>
  );
};

export default CheckWccBlock;
