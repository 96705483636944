import { Dispatch, SetStateAction, useState } from "react";
import UploadDialog from "./UploadDialog";
import { ArtifactNameValidationResult } from "../DataStructure/artifact-name-validation";

import { w3cwebsocket } from "websocket";
import CheckWccBlock from "../CheckWccBlock";

interface UploadAdfDialogProps {
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}

const UploadAdfDialog = (props: UploadAdfDialogProps) => {
  const [websocketClient, setWebsocketClient] = useState<
    w3cwebsocket | undefined
  >();
  const [artifactName, setArtifactName] = useState<string | undefined>();

  return (
    <UploadDialog
      title="Upload new adf"
      setShowPopup={props.setShowPopup}
      namePlaceholder="name.adf"
      artifactType="adf"
      nameValidator={(value: string) => {
        let status: ArtifactNameValidationResult = {
          failed: false,
          error: undefined,
        };
        if (value.length < 5) {
          status = { failed: true, error: "Too short name" };
        } else if (!value.endsWith(".adf")) {
          status = { failed: true, error: "Name must end with '.adf'" };
        }
        return status;
      }}
      onClose={() => {
        websocketClient?.close();
      }}
      artifactName={artifactName}
      setArtifactName={setArtifactName}
    >
      <CheckWccBlock
        artifactName={artifactName!}
        setWebsocketClient={setWebsocketClient}
      />
    </UploadDialog>
  );
};

export default UploadAdfDialog;
