import "./LoadingSpinner.css";

interface LoadingSpinnerProps {
  size: "small" | "big";
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  if (props.size === "big") {
    return (
      <div className="LoadingSpinner-container">
        <div className="LoadingSpinner-body"></div>
      </div>
    );
  } else {
    return (
      <div className="LoadingSpinner-container-small">
        <div className="LoadingSpinner-body-small"></div>
      </div>
    );
  }
};

export default LoadingSpinner;
