import "./Toolbar.css";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as SignOutIcon } from "./sign_out.svg";

interface ToolbarProps {
  user?: CognitoUser;
}

const Toolbar = (props: ToolbarProps) => {
  return (
    <div className="Toolbar-container">
      {props.user !== undefined ? (
        <div className="Toolbar-sign-container">
          <p className="Toolbar-sign-out-text">
            {props.user!.getSignInUserSession()?.getIdToken().decodePayload()
              .given_name +
              " " +
              props.user!.getSignInUserSession()?.getIdToken().decodePayload()
                .family_name}
          </p>
          <SignOutIcon
            className="Toolbar-sign-icon"
            onClick={() => Auth.signOut()}
          ></SignOutIcon>
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default Toolbar;
