import "./UploadResultBlock.css";
import { DataOperationResult } from "./s3-data-operations";

interface UploadResultBlockProps {
  result: DataOperationResult;
  children?: JSX.Element;
}

const UploadResultBlock = (props: UploadResultBlockProps) => {
  let block: JSX.Element;
  if (!props.result.isFailed) {
    block = (
      <div className="UploadResultBlock-container UploadResultBlock-container-success">
        <p className="UploadResultBlock-header UploadResultBlock-header-success">
          Success
        </p>
        {props.children}
      </div>
    );
  } else {
    block = (
      <div className="UploadResultBlock-container UploadResultBlock-container-error">
        <p className="UploadResultBlock-header UploadResultBlock-header-error">
          Error
        </p>
        <p className="UploadResultBlock-element">{props.result.error}</p>
      </div>
    );
  }
  return block;
};

export default UploadResultBlock;
