import "./Popup.css";

interface PopupProps {
  title: string;
  show: boolean;
  onClose: (_: boolean) => void;
  children: JSX.Element;
}

const Popup = (props: PopupProps) => {
  const closeHandler = (e: any) => {
    props.onClose(false);
  };

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className="Popup-overlay"
    >
      <div className="Popup-container">
        <h2 className="Popup-header">
          {props.title.length < 20
            ? props.title
            : props.title.slice(0, 17) + "..."}
        </h2>
        <span className="Popup-close" onClick={closeHandler}>
          &times;
        </span>
        <div className="Popup-content">{props.children}</div>
      </div>
    </div>
  );
};

export default Popup;
