import { Dispatch, SetStateAction, useState } from "react";
import "./TextInput.css";
import { ArtifactNameValidator } from "./DataStructure/artifact-name-validation";

interface TextInputProps {
  header: string;
  placeholder: string;
  value: string | undefined;
  validateInput: ArtifactNameValidator;
  setValue: Dispatch<SetStateAction<string | undefined>>;
  onError: () => void;
  onSuccess: () => void;
}

const TextInput = (props: TextInputProps) => {
  const [error, setError] = useState<string | undefined>();
  const [errorColor, setErrorColor] = useState("grey");

  return (
    <div className="TextInput-container">
      <b className="TextInput-header">{props.header}</b>
      <div>
        <input
          type="text"
          value={props.value}
          placeholder={props.placeholder}
          onChange={({ target: { value } }) => {
            setError(undefined);
            props.setValue(value);
            const { failed, error } = props.validateInput(value);
            if (failed) {
              setErrorColor("grey");
              setError(error);
              props.onError();
            } else {
              props.onSuccess();
            }
          }}
          onBlur={({ target: { value } }) => {
            if (error !== undefined) {
              setErrorColor("red");
            }
          }}
        />
      </div>
      {error ? (
        <p className={`TextInput-error TextInput-error-${errorColor}`}>
          {error}
        </p>
      ) : (
        <p className="TextInput-error"></p>
      )}
    </div>
  );
};

export default TextInput;
